<template>
    <v-data-table
        :headers="headers"
        :items="documentList"
        :loading="documentListLoading"
        :loading-text="loadingText"
        :no-data-text="noDataText"
        sort-by="document_date"
        sort-desc
    >
        <template v-slot:item.view="{ item }">
            <v-btn
                color="primary"
                @click="openDocument(item.id)"
                small
            >
                View <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import {getDocumentURL} from "../../utils/api.utils";

    export default {
        name: "DocumentsTable",
        props: [
          'documentType'
        ],
        data () {
            return {
                headers: [
                    {text: 'Type', value: 'document_subtype'},
                    {text: 'For', value: 'subtype_value'},
                    {text: 'Title', value: 'document_title'},
                    {text: 'Date', value: 'document_date'},
                    {text: '', value: 'view', sortable: false},
                ]
            }
        },

        computed: {
            statements(){
                return this.$store.getters.statements
            },

            research() {
                return this.$store.getters.research
            },

            documentListLoading(){
                return this.$store.getters.documentsLoading
            },

            documentList(){
                if (this.documentType.toLowerCase() === 'statements'){
                    return this.statements
                } else if(this.documentType.toLowerCase() === 'research'){
                    return this.research
                }

                return []
            },

            loadingText(){
                return 'Please wait while we retrieve your ' + this.documentType + '.'
            },

            noDataText(){
                return 'You don\'t have any ' + this.documentType + ' available at this time.'
            }
        },

        methods: {
            openDocument: async function (documentID) {
                let docTab = window.open('about:blank');
                docTab.document.write('Loading document. Please wait...');
                try {
                    let response = await getDocumentURL(documentID);
                    docTab.location = await response.data.file_url;
                } catch (e) {
                    docTab.document.body.innerHTML = "";
                    docTab.document.write('Document load failed.');
                    console.log(e)
                }
            }

        }
    }
</script>

<style scoped>
.theme--light.v-data-table {
    background-color: #fafafa !important;
}
</style>